<template>
  <div class="list-user admin-list-user admin_container_default">
    <div class="list-user-table p-0">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            ユーザー管理
          </div>
        </div>
        <div class="row-input admin_center"></div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button v-on:click="createUser()" class="button-create-user">
            新規追加
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <!-- <div class="row">
        <div class="row-input col-sm-4 col-md-4 col-lg-3">
          <label
            class="w-100 font-weight-bold"
            style="padding-left: 40px; padding-right: 40px"
            >ユーザー名</label
          >
          <div class="cp-4 pr-0">
            <input class="form-control w-100" v-model="name" />
          </div>
        </div>
        <div class="row-input col-sm-4 col-md-4 col-lg-3 mx-4">
          <label
            class="w-100 font-weight-bold"
            style="padding-left: 40px; padding-right: 40px"
            >メールアドレス</label
          >
          <div class="cp-4 pr-0">
            <input class="form-control w-100" v-model="email" />
          </div>
        </div>
      </div> -->
      <div class="cp-4 w-50">
        <input
          class="form-control col-sm-12"
          v-model="keywords"
          style="height: 35px"
          placeholder="検索"
          @keydown.enter.prevent="getListUsers()"
        />
      </div>
      <div class="row d-flex jsutify-content-center cpx-15">
        <div class="col-6 ml-0 px-4 d-flex align-items-center">
          <div class="col-3 p-0">
            <label class="col font-weight-bold">会員登録日</label>
          </div>
          <div
            class="col-4 fd-admin-listproperty-card-filter filter-date w-100 p-0"
          >
            <date-picker
              name="date"
              v-model="dateRegis_from"
              :config="optionsDate"
              autocomplete="off"
              placeholder=""
              @input="getListUsers()"
            ></date-picker>
            <!-- <b-form-datepicker
              v-model="dateRegis_from"
              v-b-tooltip.hover.top
              title="日付を選択"
              placeholder="日付を選択"
              dropup
              v-bind:date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="ja"
              max="9999-12-31"
              labelHelp="カーソルキーを利用して、カレンダーの日付を移動します。"
              @input="getListUsers()"
            ></b-form-datepicker> -->
          </div>
          <div class="col-1 p-0 text-center">
            <span class="custom-span text-center p-0 justify-content-center"
              >~
            </span>
          </div>
          <div class="col-4 fd-admin-listproperty-card-filter filter-date p-0">
            <date-picker
              name="date"
              v-model="dateRegis_to"
              :config="optionsDate"
              autocomplete="off"
              placeholder=""
              @input="getListUsers()"
            ></date-picker>
            <!-- <b-form-datepicker
              v-model="dateRegis_to"
              v-b-tooltip.hover.top
              title="日付を選択"
              placeholder="日付を選択"
              dropup
              v-bind:date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="ja"
              max="9999-12-31"
              labelHelp="カーソルキーを利用して、カレンダーの日付を移動します。"
              @input="getListUsers()"
            ></b-form-datepicker> -->
          </div>
        </div>
        <div class="col-6 row ml-0 align-items-center pr-5">
          <div class="col-3 p-0">
            <label class="col-12 font-weight-bold">ログイン日</label>
          </div>
          <div class="row-input col-4">
            <div class="fd-admin-listproperty-card-filter filter-date">
              <date-picker
                name="date"
                v-model="dateLogout_from"
                :config="optionsDate"
                autocomplete="off"
                placeholder=""
                @input="getListUsers()"
              ></date-picker>
              <!-- <b-form-datepicker
                v-model="dateLogout_from"
                v-b-tooltip.hover.top
                class="w-100 mr-2"
                title="日付を選択"
                placeholder="日付を選択"
                dropup
                v-bind:date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                locale="ja"
                max="9999-12-31"
                labelHelp="カーソルキーを利用して、カレンダーの日付を移動します。"
                @input="getListUsers()"
              ></b-form-datepicker> -->
            </div>
          </div>
          <div class="col-1 p-0">
            <span class="col-auto custom-span text-center p-0">~</span>
          </div>
          <div class="row-input col-4 p-0">
            <div class="fd-admin-listproperty-card-filter filter-date">
              <date-picker
                name="date"
                v-model="dateLogout_to"
                :config="optionsDate"
                autocomplete="off"
                placeholder=""
                @input="getListUsers()"
              ></date-picker>
              <!-- <b-form-datepicker
                v-model="dateLogout_to"
                v-b-tooltip.hover.top
                class="w-100 mr-2"
                title="日付を選択"
                placeholder="日付を選択"
                dropup
                v-bind:date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                locale="ja"
                max="9999-12-31"
                labelHelp="カーソルキーを利用して、カレンダーの日付を移動します。"
                @input="getListUsers()"
              ></b-form-datepicker> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-4">
          <label
            class="col-sm-12 font-weight-bold"
            style="padding-left: 40px; padding-right: 40px"
          >
            所持コンテンツ名
          </label>
          <div class="fd-admin-listproperty-card-filter filter-date cp-4 pr-0">
            <multiselect
              class="col-sm-12 col-lg-12 p-0"
              v-model="content_id"
              placeholder="購入付与スラグ追加"
              label="text"
              track-by="value"
              :options="listContentOptions"
              :multiple="true"
              :taggable="false"
                            :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              :loading="loadingContent"
              style=""
              v-bind:class="{ 'error-input': errorContentIdRequired }"
              @change="setOptionUser()"
              @open="clickScrollContent()"
            ></multiselect>
          </div>
        </div>
        <div class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-4">
          <label
            class="col-sm-12 font-weight-bold"
            style="padding-left: 40px; padding-right: 40px"
          >
            所持スラグ名
          </label>
          <div class="fd-admin-listproperty-card-filter filter-date cp-4 pr-0">
            <multiselect
              v-model="slag_type_2"
              tag-placeholder="閲覧付与スラグを追加してください"
              class="col-sm-12 col-lg-12 px-0"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
                            :showNoResults="false"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="row-input col-sm-4 col-md-4 col-lg-3">
          <label
            class="w-100 font-weight-bold"
            style="padding-left: 40px; padding-right: 40px"
            >フリーワード</label
          >
          <div class="cp-4 pr-0">
            <input class="form-control w-100" v-model="freeword" />
          </div>
        </div>
      </div> -->
      <!-- <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListUsers()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div> -->
      <Tables :items="renderList" :fields="fields" :itemsPerPageSelect="{}">
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:id="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            <div v-if="item.created_at !== null">
              {{
                new Date(item.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
            </div>
          </td>
        </template>
        <template v-slot:logout_at="{ item }">
          <td>
            <div v-if="item.logout_at !== null">
              {{
                new Date(item.logout_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
            </div>
          </td>
        </template>
        <template v-slot:name="{ item }">
          <td>
            <div v-if="item.name">
              {{ item.name }}
            </div>
            <div v-else></div>
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <div class="w-5 d-flex align-items-center">
              <router-link
                :to="{
                  name: $route.params.shopId ? 'edit user' : 'edit user domain',
                  params: { shopId: $route.params.shopId, id: item.id },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 btn-other bg-00ab82"
                  color="success"
                  square
                  v-on:click="getListUsers()"
                  variant="outline"
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  詳細確認
                </CButton>
              </router-link>
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'purchase user'
                    : 'purchase user domain',
                  params: { id: item.id },
                  query: {
                    date_from: dateFromSend,
                    date_to: dateToSend,
                  },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 btn-other bg-0076ba"
                  color="warning"
                  square
                  v-on:click="getListUsers()"
                  variant="outline"
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  購入履歴
                </CButton>
              </router-link>
            </div>
            <div class="d-flex align-items-center">
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'ListUserNote'
                    : 'ListUserNote domain',
                  params: { id: item.id },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 btn-other bg-f27200"
                  color="primary"
                  square
                  v-on:click="getListUsers()"
                  variant="outline"
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  ノート
                </CButton>
              </router-link>
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'Chat Admin'
                    : 'Chat Admin domain',
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 btn-other bg-d41876"
                  color="info"
                  square
                  variant="outline"
                  size="sm"
                >
                  質問機能
                </CButton>
              </router-link>
              <div class="d-flex flex-column align-items-center">
                <CButton
                  class="mx-1 btn-other btn-del"
                  color="danger"
                  square
                  v-on:click="showConfirm(item.id)"
                  variant="outline"
                  size="sm"
                  v-b-modal.delete-modal-user
                >
                  削除
                </CButton>
              </div>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listUser.total"
      >
        <p class="font-weight-bold">
          {{ listUser.total }}件中{{ listUser.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listUser.last_page"
      >
        <CPagination
          v-if="listUser.total"
          :activePage.sync="page"
          :pages="listUser.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-user" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>ブロックしてもよろしいですか？</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="blockNewUser()"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-user')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListUser",
  data() {
    return {
      fields: tableFields.USER,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataModal: "",
      name: "",
      keywords: "",
      email: "",
      dateRegis_from: "",
      dateRegis_to: "",
      dateLogout_from: "",
      dateLogout_to: "",
      limit: 10,
      page: 1,
      dateFromSend: "",
      dateToSend: "",
      listContentOptions: [],
      loadingContent: false,
      errorContentIdRequired: false,
      content_id: [],
      listSlagOptions: [],
      slag_type_2: [],
      freeword: null,
      listSlagSelect: [],
      listContentSelect: [],
      lengthList: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      optionsDate: {
        format: "YYYY-MM-DD HH:mm:ss",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
    };
  },
  components: {
    Tables,
  },
  async created() {
    await this.getListUsers(1);
    this.lengthList = this.listUser.total;
    const requestContent = {
      data: {
        shop_id: this.shop_id,
      },
      page: 1,
    };
    this.getListContent(requestContent);
    this.getALLlistSlag(requestContent.data);
  },
  computed: {
    ...mapGetters([
      "listUser",
      "message",
      "success",
      "error",
      "listContent",
      "listSlag",
    ]),
    renderList() {
      if (this.listUser) {
        return this.listUser.data;
      }
      return [];
    },
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.getListUsers(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listUser() {
      this.page = this.listUser.current_page;
    },
    page() {
      this.getListUsers(this.page);
    },
    listContent() {
      let listContent = [];
      listContent = this.listContent.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      listContent.forEach((element) => {
        this.listContentOptions.push(element);
      });
      this.loadingContent = false;
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
  },
  methods: {
    ...mapActions({
      getListUser: "getListUser",
      destroyUserCDEA: "destroyUserCDEA",
      getListContent: "getListContent",
      getALLlistSlag: "getALLlistSlag",
    }),
    createUser() {
      this.$router.push({
        name: this.$route.params.shopId ? "create user" : "create user domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    async getListUsers(page) {
      this.listContentSelect = [];
      this.listSlagSelect = [];
      if (page === undefined) {
        page = this.page;
      }
      if (this.slag_type_2 !== null) {
        this.slag_type_2.map((item) => {
          this.listSlagSelect.push(item.slag_id);
        });
      }
      if (this.content_id !== null) {
        this.content_id.map((item) => {
          this.listContentSelect.push(item.value);
        });
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          keywords: this.keywords,
          created_from: this.dateRegis_from,
          created_to: this.dateRegis_to,
          logout_from: this.dateLogout_from,
          logout_to: this.dateLogout_to,
          limit: this.limit,
          slag_id: this.listSlagSelect,
          content_id: this.listContentSelect,
        },
      };
      await this.getListUser(formData);
    },
    async blockNewUser() {
      const formBlock = {
        id: this.dataModal,
        shop_id: this.shop_id,
      };
      await this.destroyUserCDEA(formBlock);
      this.$nextTick(() => {
        this.$bvModal.hide("delete-modal-user");
      });
      this.getListUsers(this.page);
    },
    editUser(id) {
      this.$router.push({
        name: this.$route.params.shopId ? "edit user" : "edit user domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListUsers(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminBox" });
      } else {
        this.$router.push({ name: "DashboardAdminBox domain" });
      }
    },
    resetFieldSearch() {
      this.name = "";
      this.email = "";
      this.dateRegis_from = "";
      this.dateRegis_to = "";
      this.dateLogout_from = "";
      this.dateLogout_to = "";
      this.slag_type_2 = [];
      this.content_id = [];
      this.freeword = "";
      this.listSlagSelect = [];
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          name: this.name,
          email: this.email,
          created_from: this.dateRegis_from,
          created_to: this.dateRegis_to,
          logout_from: this.dateLogout_from,
          logout_to: this.dateLogout_to,
          limit: this.limit,
          slag_id: this.listSlagSelect,
          content_id: this.listContentSelect,
          words: this.freeword,
        },
      };
      this.getListUser(formData);
    },
    scrollContent() {
      if (
        Math.round(
          document.getElementsByClassName("multiselect__content-wrapper")[0]
            .scrollTop +
            document.getElementsByClassName("multiselect__content-wrapper")[0]
              .clientHeight
        ) ===
        document.getElementsByClassName("multiselect__content-wrapper")[0]
          .scrollHeight
      ) {
        this.loadingContent = true;
        const requestContent = {
          data: {
            shop_id: this.shop_id,
          },
          page: this.page,
        };
        this.page += 1;
        this.getListContent(requestContent);
      }
    },
    clickScrollContent() {
      document
        .getElementsByClassName("multiselect__content-wrapper")[0]
        .addEventListener("scroll", this.scrollContent);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-span {
  @media (max-width: 420px) {
    display: none;
  }
}
</style>
